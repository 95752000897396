import { getDefaultCenterId } from '@teampetfriends/utils';

export const SUPPLY_REQUEST_DETAIL_INITIAL_VALUES = {
  historyList: [],
  vendorInfo: {
    businessNumber: '',
    displayName: '',
    vendorId: '',
  },
  requestInfo: {
    productSupplyRequestDay: undefined,
    productSupplyTypeCode: '',
    productSupplyTypeCodeName: '',
    requestWorkerId: '',
    requestWorkerName: '',
    statusCode: '',
    prepaidSettlement: 'N',
  },
  receiveInfo: {
    centerId: `${getDefaultCenterId(import.meta.env.VITE_SERVICE_ENV)}`,
    centerName: '',
    inboundWikey: '',
    receiveAvailableDay: undefined,
    receiveDelay: '',
    receiveDelayAvailableDay: undefined,
    receiveDelayReason: '',
    receiveRequestDay: undefined,
  },
  commentInfo: {
    petfriendsComment: '',
    vendorComment: '',
  },
  totalInfo: {
    totalAvailableReceiveQuantity: 0,
    totalItemKindCount: 0,
    totalItemQuantity: 0,
    totalPrice: 0,
    totalReceivedPrice: 0,
  },
  itemList: [],
  transportInfo: {},
};

export const MAPPING_STATUS_CODE = {
  '': 'PETFRIENDS_DRAFT',
};

export default { SUPPLY_REQUEST_DETAIL_INITIAL_VALUES };
