import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getScCenterList } from 'commons/store/modules/vendor/supply';

import Card from 'components/Card';
import DatePicker from 'components/DatePicker';
import ResultHeader from 'components/ResultHeader';
import Select from 'components/Select';
import Table from 'components/Table';
import TextareaSearchbar from 'components/TextareaSearchbar';
import { Flex } from 'styles/components';
import Button from 'components/Button';
import toastify from 'commons/toast';
import { translateObjectKeys } from 'commons/helper';
import useDownloadExcel from 'hooks/useDownloadExcel';
import { INITIAL_STATE, MAIN_COLUMNS, EXCEL_MAPPING_KEYS } from './constant';
import { useReverseOptions } from './hooks';
import { useSCReverseList } from './apis';
import SCRverseDetailModal from './components/SCReverseDetailModal';

function ReversePage() {
  const dispatch = useDispatch();
  const { centerList } = useSelector((state) => state.vendorSupply);
  const [forms, setForms] = useState(INITIAL_STATE);
  const { downloadExcel } = useDownloadExcel({
    excelFileName: '반품 검색 결과',
    sheetFormat: EXCEL_MAPPING_KEYS,
  });

  const options = useReverseOptions();

  const { executeHandler, scReverseList, total } = useSCReverseList(forms);

  useEffect(() => {
    if (centerList.length) return;
    dispatch(getScCenterList());
  }, []);

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setForms((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeSearchHandler = (key) => (e) => {
    onChangeHandler({ target: { name: key, value: e.target.value } });
  };

  const onChangeDatePickerHandler = (key) => (date) => {
    onChangeHandler({ target: { name: key, value: date } });
  };

  const search = () => {
    executeHandler();
  };

  const reset = () => {
    setForms(INITIAL_STATE);
  };

  const translateList = (list) => {
    return list.map((item) => translateObjectKeys(item, EXCEL_MAPPING_KEYS));
  };
  const downloadExcelList = () => {
    if (!scReverseList.length) {
      toastify('조회된 내역이 없습니다.', { variant: 'error' });
      return;
    }

    downloadExcel(translateList(scReverseList));
  };

  const renderButtons = [
    <Button type='button' $variant='success' onClick={downloadExcelList}>
      엑셀 다운로드
    </Button>,
  ];

  return (
    <>
      <section>
        <Card title='반품관리'>
          <Flex $flexDirection='column' $gap='var(--space-l)'>
            <Flex $gap='var(--space-m)'>
              <Select
                id='status'
                name='status'
                value={forms.status}
                options={[{ key: '', value: '전체' }, ...options.reverseStatus]}
                onChange={onChangeHandler}
                label='반품상태'
              />
              <Select
                id='reason'
                name='reason'
                value={forms.reason}
                options={[{ key: '', value: '전체' }, ...options.reverseReason]}
                onChange={onChangeHandler}
                label='반품사유'
              />
              <Select
                id='centerId'
                name='centerId'
                label='출고센터'
                value={forms.centerId}
                options={[{ key: '', value: '전체' }, ...centerList]}
                onChange={onChangeHandler}
              />
            </Flex>
            <Flex $gap='var(--space-m)'>
              <DatePicker
                label='반품등록일'
                id='createdDay'
                startDate={forms.createdDay[0]}
                endDate={forms.createdDay[1]}
                onChange={onChangeDatePickerHandler('createdDay')}
                selectsRange
              />
              <DatePicker
                label='출고완료일'
                id='completeDay'
                startDate={forms.completeDay[0]}
                endDate={forms.completeDay[1]}
                onChange={onChangeDatePickerHandler('completeDay')}
                selectsRange
              />
            </Flex>
            <TextareaSearchbar
              name='searchCondition'
              textareaValue={forms.searchWord}
              onTextareaChange={onChangeSearchHandler('searchWord')}
              radios={options.reverseScSearchCondition || []}
              checkedValue={forms.searchCondition}
              onCheckedValueChange={onChangeHandler}
              onSearchClick={search}
              onResetClick={reset}
            />
          </Flex>
        </Card>
        <Card title='반품내역'>
          <ResultHeader totalCount={total} buttons={renderButtons} />
          <Table columns={MAIN_COLUMNS} data={scReverseList} />
        </Card>
      </section>
      <SCRverseDetailModal />
    </>
  );
}

export default ReversePage;
