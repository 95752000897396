export const SAMPLE_DATA = [
  {
    0: '품목ID',
    1: '거래처 번호',
    2: '과세구분',
    3: '기준 공급가(₩)',
    4: '최소주문수량(EA)',
    5: '품목 바코드',
    6: '박스 바코드',
    7: '박스(EA)',
    8: '팔레트(EA)',
    9: '단수/단포(EA)',
    10: '가로(cm)',
    11: '세로(cm)',
    12: '높이(cm)',
    13: '중량(kg)',
    14: '용량(ml)',
    15: '보관온도',
    16: '임박전환(일)',
  },
];

export const COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목유형', accessor: 'productType' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '상태', accessor: 'productStatus' },

  { Header: '대분류', accessor: 'productGroup1Name' },
  { Header: '중분류', accessor: 'productGroup2Name' },
  { Header: '소분류', accessor: 'productGroup3Name' },
  { Header: '세분류', accessor: 'productDetailGroup' },

  { Header: '거래처 번호', accessor: 'vendorId' },
  { Header: '거래처 명', accessor: 'vendorName' },

  { Header: '거래처 차수', accessor: 'degree' },

  { Header: '과세구분', accessor: 'tax' },
  { Header: '기준 공급가(₩)', accessor: 'standardUnitPrice' },
  { Header: '최소주문수량(EA)', accessor: 'minimumOrderQuantity' },

  { Header: '품목 바코드', accessor: 'itemBarcode' },
  { Header: '박스 바코드', accessor: 'boxBarcode' },
  { Header: '박스(EA)', accessor: 'boxUnit' },
  { Header: '팔레트(EA)', accessor: 'pltUnit' },
  { Header: '단수/단포(EA)', accessor: 'outboundDivisionUnit' },

  { Header: '가로(cm)', accessor: 'width' },
  { Header: '세로(cm)', accessor: 'length' },
  { Header: '높이(cm)', accessor: 'height' },
  { Header: '중량(kg)', accessor: 'weight' },
  { Header: '용량(ml)', accessor: 'volume' },

  { Header: '보관온도', accessor: 'storageTemperature' },
  { Header: '임박전환(일)', accessor: 'imminentStockConversionDays' },
  // { Header: '출고불가(일)', accessor: 'noShippingConversionDays' },
  { Header: 'Message', accessor: 'errorMessage' },
];

export default { SAMPLE_DATA };
