// 배포 트리거
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import PrivateRoute from 'router/PrivateRoute';

import GlobalStyle from 'styles/globalStyle';

import ModalContainer from 'containers/ModalContainer';

import LoginPage from 'pages/common/LoginPage';
import AdminRoute from 'pages/admin/AdminRoute';
import PrepaidInfoPage from 'pages/vendor/PrepaidInfoPage';
import DashboardPage from 'pages/vendor/DashboardPage';
import ReversePage from 'pages/vendor/ReversePage';
import VendorInfoPage from 'pages/vendor/VendorInfoPage';
import ProductManagePage from 'pages/vendor/ProductManagePage';
import SupplyManagePage from 'pages/vendor/SupplyManagePage';
import ReceivingManagePage from 'pages/vendor/ReceivingManagePage';
import SupplyRegisterPage from 'pages/vendor/SupplyRegisterPage';
import SettlementListPage from 'pages/vendor/SettlementListPage';
import { getTokenData } from 'commons/token';
import { useAuthInfo } from 'hooks/useSyncAuth';

import { useCrossBrowser } from '@teampetfriends/react-hooks';

function App() {
  const { storage } = useAuthInfo(['accessToken']);
  const { userInfo } = useSelector((state) => state.user);

  const user = getTokenData();

  useCrossBrowser();

  const isAdmin = useMemo(() => {
    if (!userInfo.accessToken) return false;

    return !user?.is_vendor;
  }, [userInfo, storage]);

  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <Switch>
        {/* admin routes */}

        <PrivateRoute path='/admin' component={AdminRoute} />

        {/* vendor routes */}
        <PrivateRoute path='/dashboard' component={DashboardPage} />
        <PrivateRoute path='/reverse' component={ReversePage} />
        <PrivateRoute path='/vendor' component={VendorInfoPage} />

        <PrivateRoute path='/product/list' component={ProductManagePage} />
        <PrivateRoute
          path={['/supply/register/:productSupplyRequestId', '/supply/register']}
          component={SupplyRegisterPage}
        />
        <PrivateRoute path='/supply/list' component={SupplyManagePage} />
        <PrivateRoute path='/receiving/list' component={ReceivingManagePage} />
        <PrivateRoute path='/prepaid' component={PrepaidInfoPage} />
        <PrivateRoute path='/settlement/list' component={SettlementListPage} />
        <Route path='/login' component={LoginPage} />
        <PrivateRoute path='*'>
          <Redirect to={!isAdmin ? '/dashboard' : '/admin/dashboard'} />
        </PrivateRoute>
      </Switch>
      <ModalContainer />
    </>
  );
}

export default App;
