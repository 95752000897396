import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getSettlementList, postSettlementListComplete } from 'service/hq/settlement';

import toastify from 'commons/toast';
import useDownloadExcel from 'hooks/useDownloadExcel';
import { isEmpty, translateList, thousandSeparator, translateFieldsByHash } from 'commons/helper';
import {
  fetchSettlementCompleteList,
  setSelectedDatas,
  setSelectedSettlementId,
} from 'commons/store/modules/admin/settlement';

import {
  PAYMENT_DATE_HASH,
  PAYMENT_METHOD_HASH,
  SETTLEMENT_STATUS_TYPE_HASH,
  TAX_INVOICE_TYPE_HASH,
} from 'commons/hash';

import { useGlobalModals, useDispatchModals } from 'hooks/pages';

import { SETTLEMENT_COMPLETE, SETTLEMENT_PREPAID } from 'pages/admin/settlement/constant';

import Cell from 'components/Table/components/Cell';
import Card from 'components/Card';
import Button from 'components/Button';
import PaginationTable from 'components/Table/PaginationTable';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';

import {
  SETTLEMENT_COMPLETE_LIST_TABLE_COLUMNS,
  MAPPING_KEYS,
  TABLE_OPTIONS,
} from '../../commons/constant';

import Styled from './styled';

const CompleteTableContainer = ({ match: { path } }) => {
  const dispatch = useDispatch();
  const [, setModalOpen] = useGlobalModals();
  const dispatchModalOpen = useDispatchModals(path);
  const history = useHistory();
  const { downloadExcel } = useDownloadExcel({
    excelFileName: '정산 완료 검색 결과',
    sheetFormat: MAPPING_KEYS,
  });

  const { searchFilter, tableData, selectedDatas, error } = useSelector(
    (state) => state.settlement[SETTLEMENT_COMPLETE]
  );
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if (!error) return;
    toastify('검색에 실패했습니다. 다시 시도해주세요.', { variant: 'error' });
  }, [error]);

  const mappingExcelData = (list) =>
    list.map((item) => {
      const keys = Object.keys(item);

      return keys.reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: Array.isArray(item[cur]) ? item[cur].join(',') : item[cur],
        }),
        {}
      );
    });

  const handleExcelDownloadClick = async () => {
    if (isEmpty(tableData.content)) {
      toastify('검색결과가 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    try {
      const response = await getSettlementList({
        ...searchFilter,
        pageNumber: 0,
        pageSize: 10000,
      });

      const mappingData = mappingExcelData(response.data?.content);

      downloadExcel(translateList(translateFieldsByHash(mappingData) || [], MAPPING_KEYS));
      toastify('액셀 파일이 다운로드 되었습니다.', { variant: 'success' });
    } catch (err) {
      toastify('액셀 파일이 다운로드에 실패했습니다.', { variant: 'error' });
    }
  };

  const handleAdvancePaymentUseClick = () => {
    if (!selectedDatas?.length) {
      toastify('선택한 항목이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }
    history.push(SETTLEMENT_PREPAID);
  };

  const handleSettlementCompleteClick = async () => {
    if (!selectedDatas?.length) {
      toastify('선택한 항목이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    const settlementIdList = selectedDatas.map((data) => data.settlementId);

    const onConfirmClick = async () => {
      try {
        await postSettlementListComplete({ settlementIdList });

        toastify('정산 완료 처리 되었습니다');
        dispatch(fetchSettlementCompleteList(searchFilter));
      } catch (err) {
        if (err.response?.data?.code === 400) {
          toastify(err.response?.data?.message, {
            variant: 'error',
          });
          return;
        }
        toastify('정산 완료 실패했습니다. 다시 시도해주세요.', { variant: 'error' });
      } finally {
        setModalOpen('alert', false);
      }
    };

    const onCancelClick = async () => {
      setModalOpen('alert', false);
    };

    setModalOpen('alert', true, {
      title: '정산 완료 클릭',
      content: '정산 완료를 실행하시겠습니까?',
      buttons: [
        { text: '예', onClick: onConfirmClick },
        { text: '아니오', onClick: onCancelClick },
      ],
    });
  };

  const handleCheckBoxClick = ({ selectedFlatRows }) => {
    /** 체크박스 비활성화 항목 제외 된 목록 */
    const filteredDisabledItemList = selectedFlatRows
      .filter((item) => item.original.settlementStatusType === 'REGISTER')
      .map((item) => item.original);
    dispatch(
      setSelectedDatas({
        path,
        selectedDatas: filteredDisabledItemList,
      })
    );
  };

  const handlePageChange = ({ state }) => {
    const params = {
      ...searchFilter,
      pageNumber: state.pageIndex,
    };
    dispatch(fetchSettlementCompleteList(params));
  };

  const handleDetailModalOpen = (selectedSettlementId) => {
    dispatch(
      setSelectedSettlementId({
        path,
        selectedSettlementId,
      })
    );
    dispatchModalOpen('detail', true);
  };

  const renderCell = ({ column, value = [], render, row }) => {
    switch (column?.id) {
      case 'selection':
        return (
          <Cell row={{ ...row, disabled: row.original.settlementStatusType !== 'REGISTER' }} />
        );
      case 'paymentMethod':
        return PAYMENT_METHOD_HASH[value];
      case 'paymentDate':
        return PAYMENT_DATE_HASH[value];
      case 'settlementId':
        return (
          <button
            className='btn-link'
            type='button'
            onClick={() => handleDetailModalOpen(row.original.settlementId)}
          >
            {value}
          </button>
        );
      case 'inboundWiKeys':
      case 'requestIds':
      case 'reverseNumbers':
      case 'wokeys':
        return value.map((item) => <div key={item}>{item}</div>);
      case 'taxInvoiceType':
        return TAX_INVOICE_TYPE_HASH[value];
      case 'totalSettlementPrice':
      case 'totalPrepaidPrice':
        return value === null ? '0' : thousandSeparator(value) || 0;
      case 'settlementStatusType':
        return SETTLEMENT_STATUS_TYPE_HASH[value];
      default:
        return render('Cell');
    }
  };

  const resultHeaderButtons = [
    <Button $variant='success' onClick={handleExcelDownloadClick}>
      엑셀 다운로드
    </Button>,
    <Button $variant='primary' onClick={handleAdvancePaymentUseClick}>
      선지급금 사용
    </Button>,
    <Button $variant='primary' onClick={handleSettlementCompleteClick}>
      정산 완료
    </Button>,
  ];

  return (
    <Card title='정산 내역'>
      <Styled.ResultHeader
        totalCount={tableData?.totalElements || 0}
        buttons={resultHeaderButtons}
      />
      <PaginationTable
        columns={SETTLEMENT_COMPLETE_LIST_TABLE_COLUMNS}
        data={tableData?.content || []}
        setControlledPage={setPageIndex}
        onSelectChange={handleCheckBoxClick}
        onPageChange={handlePageChange}
        renderCell={renderCell}
        pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
        tableOptions={{
          ...TABLE_OPTIONS,
          pageCount: tableData?.totalPages || 0,
          useControlledState: (state) => ({
            ...state,
            pageIndex,
          }),
        }}
      />
    </Card>
  );
};

export default CompleteTableContainer;

CompleteTableContainer.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
