import { useCreateWorkbook } from '@teampetfriends/react-hooks';
import { useCallback, useEffect, useState } from 'react';

const ASCII_CODE_A = 65;
const ASCII_CODE_A_TO_Z_LENGTH = 26;

export const getExcelHeaderColumnName = (index) => {
  let columnName = '';
  while (index >= 0) {
    columnName =
      String.fromCharCode((index % ASCII_CODE_A_TO_Z_LENGTH) + ASCII_CODE_A) + columnName;
    index = Math.floor(index / ASCII_CODE_A_TO_Z_LENGTH) - 1;
  }
  return columnName;
};

/**
 * 2025-02-21
 * 배열의 형태로 엑셀이 생성되는것은 성공적이였으나, Object.entries로 배열을 생성시에, 객체 프로퍼티의 순서대로 배열이 형성되는것을
 * 보장하지않음. 따라서 기준이되는 객체의 순서대로 배열이 형성되도록 코드를 만들어줄 필요가 있었기에 다음과 같이 함수를 작성하게 됨.
 * 이때 기준값은 sheetFormatKeys의 순서이다.
 * @param {array} sheetFormatKeys - 기준이 되는 배열
 * @param {array} entriesArray - Object.entries로 생성한 배열
 * @returns 정렬된 entriesArray
 */
const sortObjectEntriesArray = (sheetFormatKeys, entriesArray) => {
  const orderMap = new Map();
  sheetFormatKeys.forEach((value, index) => orderMap.set(value, index));

  return entriesArray.sort((a, b) => {
    return (orderMap.get(a[0]) ?? Infinity) - (orderMap.get(b[0]) ?? Infinity);
  });
};

const flatExcelData = (data, sheetFormat) => {
  const sheetFormatKeys = Object.values(sheetFormat);

  return data.reduce((acc, cur) => {
    const targetArr = sortObjectEntriesArray(sheetFormatKeys, Object.entries(cur)).map(
      (item) => item[1]
    );
    acc.push(targetArr);
    return acc;
  }, []);
};

const popFlatExcelData = (data, point) => {
  return flatExcelData(data).map((item) =>
    item.slice(0, !point ? item.length - 1 : item.length - point)
  );
};

/**
 *
 * @param {number} columnCount - 헤더의 길이
 * @returns - 엑셀 헤더양식 ['A1', 'B1', 'C1', 'D1']
 */
export const generateExcelHeaders = (columnCount) => {
  const headers = [];
  for (let i = 0; i < columnCount; i += 1) {
    headers.push(`${getExcelHeaderColumnName(i)}1`);
  }
  return headers;
};

/**
 * @typedef {Object} UseDownloadExcelProps
 * @property {string} excelFileName 엑셀 파일명
 * @property {object} sheetFormat 엑셀 해더셀 포멧
 * @property {number} sliceCount 엑셀 열 노출제한수
 */

/**
 *
 * @param {UseDownloadExcelProps}
 * - excelFileName: 엑셀 파일명
 * - sheetFormat: 엑셀 해더셀 포멧 (객체내에 value에 있는 정보가 엑셀상에 노출됩니다.)
 * - sliceCount: 엑셀 열 노출제한수
 * @returns {Function} downloadExcel
 */
const useDownloadExcel = ({ excelFileName, sheetFormat, sliceCount = 0 }) => {
  const excelOrderHeader = generateExcelHeaders(Object.keys(sheetFormat).length);

  const refinedExcelSheetFormat = Object.entries(sheetFormat).map(([_, value], index) => ({
    start: excelOrderHeader[index],
    end: excelOrderHeader[index],
    title: value,
  }));

  const { download } = useCreateWorkbook({
    sheets: [{ name: excelFileName, sheetFormat: refinedExcelSheetFormat }],
    fileName: excelFileName,
  });

  const downloadExcel = useCallback(
    (data) => {
      const excelFiles = sliceCount
        ? popFlatExcelData(data, sliceCount)
        : flatExcelData(data, sheetFormat);
      download(
        { [excelFileName]: excelFiles },
        {
          cb: () => true,
        }
      );
    },
    [excelFileName]
  );

  return { downloadExcel };
};

export default useDownloadExcel;
