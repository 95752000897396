import ThousandSeperator from 'components/ThousandSeperator';

export const HELPER_TEXTS = [
  {
    term: '발주유형',
    description:
      '신규 / 리오더 / 프로모션 / 품절대비 / 인상대비 / 행사대비 / 대체발주 / 소매 / 광고대체',
  },
  {
    term: '입고센터',
    description: 'WMS 센터코드 ex) C0001, C0002 …',
  },
  {
    term: '입고요청일자',
    description: 'yyyy-mm-dd 형식으로 입력',
  },
  {
    term: '품목ID',
    description: '재고항목코드',
  },
  {
    term: '증정여부',
    description: 'Y or N',
  },
  {
    term: '선지급금 정산 여부',
    description: 'Y or N',
  },
];

export const COLUMNS = [
  {
    Header: '거래처 번호',
    accessor: 'vendorId',
  },
  {
    Header: '발주유형',
    accessor: 'productSupplyTypeCode',
  },
  {
    Header: '선지급금 정산 여부',
    accessor: 'prepaidSettlement',
  },
  {
    Header: '입고센터',
    accessor: 'centerId',
  },
  {
    Header: '입고요청일자',
    accessor: 'receiveRequestDay',
  },
  {
    Header: '품목ID',
    accessor: 'productItemId',
  },
  {
    Header: '증정여부',
    accessor: 'isFreeGift',
  },
  {
    Header: '적용 공급가',
    accessor: 'productSupplyUnitPrice',
    Cell: ThousandSeperator,
  },
  {
    Header: '발주수량',
    accessor: 'requestQuantity',
    Cell: ThousandSeperator,
  },
  {
    Header: '펫프담당자 비고',
    accessor: 'petfriendsComment',
  },
  {
    Header: '에러 메시지',
    accessor: 'errorMessage',
  },
];

export const SAMPLE_HEADER_DATA = {
  0: '거래처 번호',
  1: '발주유형',
  2: '선지급금 정산 여부',
  3: '입고센터',
  4: '입고요청일자',
  5: '품목ID',
  6: '증정여부',
  7: '적용 공급가',
  8: '발주수량',
  9: '펫프담당자 비고',
};

export const SAMPLE_DATA = [
  {
    0: 99999,
    1: 'NEW_ORDER',
    2: 'Y',
    3: 1,
    4: '2022.1.13',
    5: 94110,
    6: 'N',
    7: 3000,
    8: 4000,
    9: '비고입니다.',
  },
  {
    0: 99999,
    1: 'RE_ORDER',
    2: 'N',
    3: 1,
    4: '2022.1.13',
    5: 94110,
    6: 'N',
    7: 3000,
    8: 4000,
    9: '비고입니다.',
  },
  {
    0: 99999,
    1: 'PROMOTION',
    2: 'Y',
    3: 2,
    4: '2022.1.13',
    5: 94110,
    6: 'N',
    7: 3000,
    8: 4000,
    9: '비고입니다.',
  },
];
