import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import useDownloadExcel from 'hooks/useDownloadExcel';
import supplyApi from 'service/hq/supply';
import { handleError, isEmpty } from 'commons/helper';
import toastify from 'commons/toast';

import { Flex } from 'styles/components';
import Card from 'components/Card';
import Table from 'components/Table';
import Button from 'components/Button';
import FileUpload from 'components/FileUpload';

import { COLUMNS, HELPER_TEXTS, SAMPLE_DATA, SAMPLE_HEADER_DATA } from './commons/constant';
import { hasInvalidColumn } from './commons/helper';

const AdminSupplyUploadPage = () => {
  const [uploadResultList, setUploadResultList] = useState([]);
  const history = useHistory();

  const { downloadExcel } = useDownloadExcel({
    excelFileName: '발주 대량 업로드 샘플',
    sheetFormat: SAMPLE_HEADER_DATA,
  });

  const handleSampleDownloadClick = () => {
    downloadExcel(SAMPLE_DATA);
  };
  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    try {
      const { response } = await supplyApi.readUploadedSupplyExcel(file);
      setUploadResultList(response.data);
    } catch (error) {
      handleError(error);
    }
  };
  const handleSaveClick = async () => {
    if (isEmpty(uploadResultList)) {
      toastify('저장할 데이터가 없습니다. 파일 업로드 후에 저장버튼을 눌러주세요.', {
        variant: 'warn',
      });
      return;
    }
    if (hasInvalidColumn(uploadResultList)) {
      toastify('발주를 생성할 수 없습니다. 파일을 확인해 주세요.', { variant: 'warn' });
      return;
    }

    const params = uploadResultList.map(
      ({
        vendorId,
        productSupplyTypeCode,
        centerId,
        receiveRequestDay,
        productItemId,
        isFreeGift,
        productSupplyUnitPrice,
        requestQuantity,
        petfriendsComment,
        prepaidSettlement,
        errorMessage,
      }) => ({
        vendorId,
        productSupplyTypeCode,
        centerId,
        receiveRequestDay,
        productItemId,
        isFreeGift,
        productSupplyUnitPrice,
        requestQuantity,
        petfriendsComment,
        prepaidSettlement,
        errorMessage,
      })
    );

    try {
      await supplyApi.saveUploadedSupplyExcel(params);
      toastify('발주가 정상 등록 되었습니다.', { variant: 'success' });
      history.push('/admin/supply/list');
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Card title='발주 대량 등록'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <div>
          <b>발주 대량 등록 Guide</b>
          <p>
            [거래처번호+발주유형+입고센터+입고요청일자]를 기준으로 1개 발주가 생성되며 발주번호는
            시스템에서 자동채번 됩니다.
          </p>

          <dl>
            {HELPER_TEXTS.map(({ term, description }) => (
              <div key={term}>
                <dt>{term}</dt>
                <dd>{description}</dd>
              </div>
            ))}
          </dl>
        </div>
        <b>엑셀 업로드</b>
        <Flex $gap='var(--space-m)'>
          <Button $variant='secondary' onClick={handleSampleDownloadClick}>
            샘플 다운로드
          </Button>
          <FileUpload onChange={handleFileInputChange} />
        </Flex>
        <b>엑셀 업로드 결과</b>
        <Table columns={COLUMNS} data={uploadResultList} />
        <Flex>
          <Button onClick={handleSaveClick}>저장</Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default AdminSupplyUploadPage;
