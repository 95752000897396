import { formatDate, formatDateAsYYYYMMDD, formatStringAsDate } from 'commons/date';
import Button from 'components/Button';
import Card from 'components/Card';
import DatePicker from 'components/DatePicker';
import useFetch from 'hooks/useFetch';
import { useState } from 'react';
import instance from 'service';
import Checkbox from 'components/Checkbox';
import { downloadJsonAsExcel } from 'commons/excel';
import Remark from './Remark';

const SUPPLY_TARGET_PRODUCT_LIST_HEADER = [
  [
    '거래처번호',
    '거래처명',
    '품목코드',
    '품목명',
    '센터코드',
    '센터명',
    '적정재고',
    '일평균판매량',
    '입수량',
    '입수단위',
    'MOQ',
    '상품등급(GMvg)',
    '상품등급(Qty)',
    '품절예상일수',
    '기발주수량',
    '입고예정수량',
    '공급가(매입가)',
    '가이드수량',
    '발주담당자',
  ],
];

const fetchSupplyManagers = async () => {
  const { data } = await instance.get('/api/v2/hq/vendor-managers/supply-managers');

  return data;
};

const fetchSupplyForecasts = async ({ referenceDate, supplyManagers }) => {
  const { data } = await instance.post('/api/v2/hq/product/supply/forecast', {
    referenceDate,
    supplyManagers,
  });

  return data;
};

const today = new Date();

const AdminSupplyForecastPage = () => {
  const [referenceDate, setReferenceDate] = useState(() => formatDate(today, 'YYYY-MM-DD'));
  const [checkedSupplyManagers, setCheckedSupplyManagers] = useState([]);
  const { data: supplyManagers = [] } = useFetch(fetchSupplyManagers);
  const { execute } = useFetch(fetchSupplyForecasts, {
    initialExecute: false,
  });

  const toggleSupplyManager = ({ target }) => {
    const { checked, value } = target;
    if (checked) {
      setCheckedSupplyManagers((prevCheckedSupplyManagers) => [
        ...prevCheckedSupplyManagers,
        value,
      ]);
    } else {
      setCheckedSupplyManagers((prevCheckedSupplyManagers) =>
        prevCheckedSupplyManagers.filter((manager) => manager !== value)
      );
    }
  };

  const downloadExcel = async () => {
    const { data } = await execute({ referenceDate, supplyManagers: checkedSupplyManagers });
    const sheetName = '발주대상품목내역';
    /**
     * 2025.02.20
     * 해당 부분은 A2자리에 특수하게 처리가 필요한것으로 판단. 따라서 useDownloadExcel을 사용하지않음.
     */
    downloadJsonAsExcel({
      header: SUPPLY_TARGET_PRODUCT_LIST_HEADER,
      options: {
        origin: 'A2',
        skipHeader: true,
      },
      data,
      sheetName,
      fileName: `${sheetName}_${formatDate(today, 'YYYY-MM-DD HH:mm:ss')}`,
    });
  };

  return (
    <Card title='발주대상품목 조회'>
      <Remark />

      <div
        css={`
          display: flex;
          align-items: flex-start;
          gap: 50px;
        `}
      >
        <DatePicker
          id='referenceDate'
          label='조회 기준일'
          selected={formatStringAsDate(referenceDate)}
          onChange={(date) => setReferenceDate(formatDateAsYYYYMMDD(date))}
          maxDate={today}
        />

        <div>
          <h3
            css={`
              font-size: 15px;
              color: var(--grey700);
              font-weight: 400;
            `}
          >
            발주 담당자
          </h3>

          {supplyManagers.length !== 0 && (
            <div
              css={`
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: var(--radius-m);
                border: 1px solid var(--border-color);
                padding: 20px;
              `}
            >
              {supplyManagers.map((manager) => (
                <Checkbox
                  key={manager}
                  id={manager}
                  value={manager}
                  label={manager}
                  checked={checkedSupplyManagers.includes(manager)}
                  onChange={toggleSupplyManager}
                />
              ))}
            </div>
          )}
        </div>

        <Button
          $variant='success'
          onClick={downloadExcel}
          disabled={!referenceDate || !checkedSupplyManagers.length}
        >
          엑셀 다운로드
        </Button>
      </div>
    </Card>
  );
};

export default AdminSupplyForecastPage;
