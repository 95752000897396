import { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Flex } from 'styles/components';
import Table from 'components/Table';
import LabelAndText from 'components/LabelAndText';
import { usePageModals } from 'hooks/pages';

import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import InputLabel from 'components/InputLabel';
import Button from 'components/Button';
import { getDefaultCenterId } from '@teampetfriends/utils';

import {
  thousandSeparator,
  updateMyDataMaker,
  formattingPhoneNumberWithHypen,
} from 'commons/helper';

import toastify from 'commons/toast';
import { TransitionContainer, CardContainer, Text, SkipCheckBox } from './styled';
import { STEP2_COLUMNS, ETC_OPTIONS_VALUE_TABLE } from '../../constant';
import ConfirmModal from './ConfirmModal';
import { usePurchaseOrdersStep2Info } from '../../apis';

const paramsGenerator = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc = {
      ...acc,
      [key.replace('reverse', '').replace(/^[A-Z]/, (char) => char.toLowerCase())]: value,
    };
    return acc;
  }, {});
};

function Step2({ reverseReason: reverseReasonOptions, deliveryType }) {
  const history = useHistory();

  const query = new URL(window.location).searchParams;

  const { purchaseOrderId } = useSelector((state) => state.reverse);
  const { wmsCenterList } = useSelector((state) => state.supply);

  const [, setModalOpen] = usePageModals();
  const { info, list, setStep2List } = usePurchaseOrdersStep2Info(purchaseOrderId);

  const [params, setParams] = useState({
    reverseCenterId: `${getDefaultCenterId(import.meta.env.VITE_SERVICE_ENV)}`,
    reverseDeliveryType: 'TRUCK',
    reverseAvailableDay: null,
    reverseRecipient: '',
    reverseZipcode: '',
    reverseReason: '',
    reverseAddress: '',
    reverseContact: '',
  });

  const [etcOption, setEtcOption] = useState(''); // collect(회수) or dispose(폐기)

  const reset = () => {
    setParams({
      reverseCenterId: `${getDefaultCenterId(import.meta.env.VITE_SERVICE_ENV)}`,
      reverseDeliveryType: 'TRUCK',
      reverseAvailableDay: null,
      reverseRecipient: '',
      reverseZipcode: '',
      reverseReason: '',
      reverseAddress: '',
      reverseContact: '',
    });
    setEtcOption('');
  };

  useEffect(() => {
    reset();
  }, [purchaseOrderId]);

  const totalPrice = useMemo(
    () =>
      etcOption !== 'dispose'
        ? list.reduce((acc, cur) => {
            acc += cur.reverseAvailableQuantity * cur.purchaseOrderUnitPrice;
            return acc;
          }, 0)
        : 0,
    [JSON.stringify(list.map((item) => item.reverseAvailablePrice))]
  );

  const zzinParams = useMemo(() => {
    return {
      ...paramsGenerator(params),
      vendorId: info.vendorId,
      purchaseOrderId: info.purchaseOrderId,
      items: list
        .filter(({ reverseAvailableQuantity }) => reverseAvailableQuantity > 0)
        .map(({ purchaseOrderItemId, productItemId, reverseAvailableQuantity, productName }) => ({
          productItemId,
          purchaseOrderItemId,
          reverseAvailableQuantity,
          productName,
        })),
    };
  }, [query.get('register_step')]);

  const resetSumColumns = () => {
    setStep2List((prev) => prev.map((item) => ({ ...item, reverseAvailablePrice: 0 })));
  };

  const onChangeHandler = (key) => (e) => {
    const { value } = e.target;
    setParams((prev) => ({
      ...prev,
      [key]: key === 'reverseZipcode' ? value.replace(/[^0-9]/g, '') : value,
    }));
  };

  const onChangeDatePickerHandler = (value) => {
    onChangeHandler('reverseAvailableDay')({ target: { value } });
  };

  const onChangeReverseContact = (key) => (e) => {
    const { value } = e.target;
    const val = formattingPhoneNumberWithHypen(value);

    setParams((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const onEtcChangeHandler = (e) => {
    const value = e.target.name === etcOption ? '' : e.target.name;
    setEtcOption(value);
    if (value === 'dispose') resetSumColumns();
  };

  const validation = () => {
    const isNotValidValues = (el) => ['', null, undefined].includes(el);
    const REG_EMOJI_EXP = /\p{Extended_Pictographic}/u;
    const ONLY_NUMBER_REG_EXP = /^[0-9]*$/;

    if (Object.values(params).some(isNotValidValues)) {
      toastify('필수 값이 누락되었습니다.', { variant: 'error' });
      return false;
    }

    if (!(params.reverseAvailableDay instanceof Date)) {
      toastify('형식이 올바르지 않습니다.', { variant: 'error' });
      return false;
    }

    if (
      REG_EMOJI_EXP.test(params.reverseRecipient) ||
      isNotValidValues(params.reverseRecipient.trim())
    ) {
      toastify('수령인에 이모티콘은 입력이 제한됩니다.', { variant: 'error' });
      return false;
    }

    if (params.reverseRecipient.trim().length > 50) {
      toastify('수령인은 50자 제한입니다.', { variant: 'error' });
      return false;
    }

    if (
      params.reverseZipcode.trim().length !== 5 ||
      !ONLY_NUMBER_REG_EXP.test(params.reverseZipcode.trim())
    ) {
      toastify('우편번호 형식에 맞게 입력해주세요.', { variant: 'error' });
      return false;
    }

    if (params.reverseAddress.length > 200) {
      toastify('주소는 200자 제한입니다.', { variant: 'error' });
      return false;
    }

    if (REG_EMOJI_EXP.test(params.reverseAddress)) {
      toastify('주소에 이모티콘은 입력이 제한됩니다.', { variant: 'error' });
      return false;
    }

    return true;
  };

  const prevStep = () => {
    if (query.get('register_step') === '3') {
      history.go(-2);
      return;
    }
    history.goBack();
  };

  const setErrorItems = (restockErrorCodes) => {
    const prevList = [...list].map(({ productItemId, ...item }) => {
      if (restockErrorCodes.includes(String(productItemId))) {
        return { ...item, productItemId, isError: true };
      }
      return { ...item, productItemId };
    });

    setStep2List(prevList);
  };

  const goFinalStep = () => {
    if (!validation()) return;
    if (!list.filter(({ reverseAvailableQuantity }) => reverseAvailableQuantity > 0).length) {
      toastify('품목에 대해 수량을 입력해주세요.', { variant: 'error' });
      return;
    }

    history.push('/admin/reverse?register_step=3');
    setModalOpen('confirm', true);
  };

  const tableOptions = {
    updateMyData: updateMyDataMaker(setStep2List),
    etcOption,
  };

  useEffect(() => {
    setParams((prev) => ({ ...prev, ...ETC_OPTIONS_VALUE_TABLE[etcOption || 'default'] }));
  }, [etcOption]);

  return (
    <>
      <TransitionContainer $alignItems='flex-start' $flexDirection='column'>
        <Flex $width='100%' $height='100%' $alignItems='flex-start'>
          <CardContainer title='STEP 2. 반품정보 입력' grow={1.5}>
            <Flex $gap='var(--space-m)' $flexDirection='column'>
              <Flex $flexDirection='column'>
                <Flex $align='center'>
                  <LabelAndText label='발주번호' text={info.purchaseOrderId} />
                  <LabelAndText label='입고번호' text={info.inboundWikey} />
                </Flex>
                <Flex $align='center'>
                  <LabelAndText label='거래처 번호' text={info.vendorId} />
                  <LabelAndText label='거래처명' text={info.vendorName} />
                  <LabelAndText label='입고센터' text={info.centerName} />
                  <LabelAndText
                    label='발주금액'
                    text={thousandSeparator(info.purchaseOrderPrice) || 0}
                  />
                  <LabelAndText label='발주요청일' text={info.purchaseOrderRequestDay} />
                </Flex>
              </Flex>

              <Flex $alignItems='center' $justifyContent='space-between'>
                <Text>품목 정보</Text>
                <Flex $alignItems='center' $columnGap={10}>
                  <Text>반품사유</Text>
                  <Select
                    options={[{ key: '', value: '' }, ...reverseReasonOptions]}
                    id='reverseReason'
                    value={params.reverseReason}
                    onChange={onChangeHandler('reverseReason')}
                  />
                </Flex>
              </Flex>
              <Table
                $height='530'
                columns={STEP2_COLUMNS}
                data={list}
                tableOptions={tableOptions}
              />
              <Text style={{ textAlign: 'right' }}>{thousandSeparator(totalPrice) || 0}원</Text>
            </Flex>
          </CardContainer>

          <CardContainer title='STEP 3. 출고정보 입력' grow={1} $columnGap={10}>
            <Flex $flexDirection='column' $rowGap={20}>
              <Select
                id='reverseCenterId'
                name='reverseCenterId'
                label='출고센터'
                value={params.reverseCenterId}
                options={wmsCenterList}
                onChange={onChangeHandler('reverseCenterId')}
              />
              <Select
                id='reverseDeliveryType'
                name='reverseDeliveryType'
                label='배송유형'
                options={deliveryType}
                value={params.reverseDeliveryType}
                onChange={onChangeHandler('reverseDeliveryType')}
              />
              <DatePicker
                label='출고예정일'
                id='reverseAvailableDay'
                name='reverseAvailableDay'
                minDate={new Date()}
                isClearable={false}
                selected={params.reverseAvailableDay}
                onChange={onChangeDatePickerHandler}
              />
              <InputLabel
                label='수령인'
                id='reverseRecipient'
                name='reverseRecipient'
                value={params.reverseRecipient}
                onChange={onChangeHandler('reverseRecipient')}
                disabled={etcOption}
              />
              <InputLabel
                label='우편번호'
                id='reverseZipcode'
                name='reverseZipcode'
                value={params.reverseZipcode}
                maxLength={5}
                onChange={onChangeHandler('reverseZipcode')}
                disabled={etcOption}
              />
              <InputLabel
                label='주소'
                id='reverseAddress'
                name='reverseAddress'
                $fullWidth
                value={params.reverseAddress}
                onChange={onChangeHandler('reverseAddress')}
                disabled={etcOption}
              />
              <InputLabel
                label='연락처'
                id='reverseContact'
                name='reverseContact'
                value={params.reverseContact}
                placeholder='- 포함해서 작성해주세요. '
                onChange={onChangeReverseContact('reverseContact')}
                disabled={etcOption}
              />
              <SkipCheckBox>
                <div>
                  <input
                    id='collect'
                    name='collect'
                    type='checkbox'
                    checked={etcOption === 'collect'}
                    onChange={onEtcChangeHandler}
                  />
                  <label htmlFor='collect'>거래처 직접 회수</label>
                </div>
                <div>
                  <input
                    id='dispose'
                    name='dispose'
                    type='checkbox'
                    checked={etcOption === 'dispose'}
                    onChange={onEtcChangeHandler}
                  />
                  <label htmlFor='dispose'>자체 폐기</label>
                </div>
              </SkipCheckBox>
            </Flex>
          </CardContainer>
        </Flex>
        <Flex
          $width='100%'
          $alignItems='center'
          $justifyContent='flex-end'
          $columnGap={10}
          $paddingRight={10}
          $marginTop={26}
        >
          <Button $outline $size='large' onClick={prevStep}>
            뒤로
          </Button>
          <Button $size='large' onClick={goFinalStep}>
            다음
          </Button>
        </Flex>
      </TransitionContainer>
      <ConfirmModal body={zzinParams} setError={setErrorItems} />
    </>
  );
}

export default Step2;
