export const SEARCH_OPTIONS_INITIAL_VALUES = {
  productSupplyReceivingStatusCode: 'COMPLETION',
  receiveAvailableDay: [null, null],
  inboundCompletionDay: [null, null],
  query: '',
  inboundResultSearchCondition: 'PRODUCT_SUPPLY_REQUEST',
};

export const RECEIVING_RESULT_TABLE_COLUMNS = [
  {
    Header: '입고지 정보',
    columns: [{ Header: '입고 센터', accessor: 'centerName', Footer: '합계 :' }],
  },
  {
    Header: '발주 정보',
    columns: [
      { Header: '발주번호', accessor: 'productSupplyRequestId' },
      { Header: '입고번호', accessor: 'inboundWikey' },
      { Header: '발주유형', accessor: 'productSupplyTypeValue' },
      { Header: '발주상태', accessor: 'productSupplyRequestStatusValue' },
      { Header: '발주요청일', accessor: 'productSupplyRequestDay' },
    ],
  },
  {
    Header: '품목 정보',
    columns: [
      { Header: '품목ID', accessor: 'productItemId' },
      { Header: '품목명', accessor: 'productName' },
      {
        Header: '적용공급가(₩)',
        accessor: 'productSupplyUnitPrice',
      },
      { Header: '증정여부', accessor: 'isFreeGift' },
    ],
  },
  {
    Header: '입고 결과 개요',
    columns: [
      { Header: '입고 상태', accessor: 'productSupplyReceivingStatusValue' },
      { Header: '입고요청일', accessor: 'receiveRequestDay' },
      { Header: '입고예정일', accessor: 'receiveAvailableDay' },
      { Header: '입고완료일', accessor: 'inboundCompletionDay' },
      {
        Header: '입고예정수량',
        accessor: 'availableReceiveQuantity',
      },
      {
        Header: '입고결과수량',
        accessor: 'receivedQuantity',
      },
      {
        Header: '미입고수량',
        accessor: 'unreceivedQuantity',
      },
      {
        Header: '입고결과금액(₩)',
        accessor: 'receivedQuantityPrice',
      },
      {
        Header: '미입고금액(₩)',
        accessor: 'unreceivedQuantityPrice',
      },
    ],
  },
];

export const RECEIVING_EXCEL_MAPPING_KEYS = {
  centerName: '입고센터',
  productSupplyRequestId: '발주번호',
  inboundWikey: '입고번호',
  productSupplyTypeValue: '발주유형',
  productSupplyRequestStatusValue: '발주상태',
  productSupplyRequestDay: '발주요청일',
  supplyManagerName: '발주담당자',
  vendorId: '거래처번호',
  displayName: '거래처명',
  purchaseManager: '구매담당자',
  productItemId: '품목ID',
  productName: '품목명',
  productSupplyUnitPrice: '적용공급가(₩)',
  isFreeGift: '증정여부',
  productSupplyReceivingStatusValue: '입고상태',
  receiveRequestDay: '입고요청일',
  receiveAvailableDay: '입고예정일',
  inboundCompletionDay: '입고완료일',
  availableReceiveQuantity: '입고예정수량',
  receivedQuantity: '입고결과수량',
  unreceivedQuantity: '미입고수량',
  receivedQuantityPrice: '입고결과금액(₩)',
  unreceivedQuantityPrice: '미입고금액(₩)',
  productSupplyRequestItemId: '발주항목ID',
};

export default { SEARCH_OPTIONS_INITIAL_VALUES };
