import PropTypes from 'prop-types';

import supplyApi from 'service/hq/supply';
import { handleError, isEmpty, translateList } from 'commons/helper';
import toastify from 'commons/toast';

import Button from 'components/Button';
import { useGlobalModals } from 'hooks/pages';

import useDownloadExcel from 'hooks/useDownloadExcel';
import useRouter from 'hooks/useRouter';
import { MAPPING_KEYS_V2 } from './commons/constant';
import { getSuccessMessage, isCancelButtonDisabled, isSendButtonDisabled } from './commons/helper';

import * as Styled from './styled';
import { getFetchSupplyRequestListParams } from '../../commons/helper';

const HeaderButtons = ({
  searchOptionsSnapshot,
  selectedProductSupplyRequestList,
  fetchSupplyRequestList,
  controlledPageIndex,
}) => {
  const { push, pushWithSearchParams } = useRouter();
  const [, setModalOpen] = useGlobalModals();
  const { downloadExcel } = useDownloadExcel({
    excelFileName: '발주 관리 검색 결과',
    sheetFormat: MAPPING_KEYS_V2,
  });

  const selectedProductSupplyRequestIdList = selectedProductSupplyRequestList.map(
    (item) => item.productSupplyRequestId
  );

  const handleMultipleRegisterClick = () => push('/admin/supply/upload/supply');
  const handleSeparateRegisterClick = () => pushWithSearchParams('/admin/supply/registration');

  const handleExcelDownloadClick = async () => {
    setModalOpen('activityIndicator', true);
    try {
      const { response } = await supplyApi.getSupplyRequestList({
        ...getFetchSupplyRequestListParams(searchOptionsSnapshot.current, 0, 30000),
        isExcel: true,
      });
      downloadExcel(translateList(response.data, MAPPING_KEYS_V2));
    } catch (error) {
      handleError(error);
    } finally {
      setModalOpen('activityIndicator', false);
    }
  };

  const updateStatus = async (status) => {
    try {
      await supplyApi.updateStatus(selectedProductSupplyRequestIdList, status);
      toastify(getSuccessMessage(selectedProductSupplyRequestIdList, status), {
        variant: 'success',
      });
      fetchSupplyRequestList(
        getFetchSupplyRequestListParams(searchOptionsSnapshot.current, controlledPageIndex)
      );
    } catch (error) {
      handleError(error);
    }
  };

  const handleStatusUpdate = (status) => {
    if (isEmpty(selectedProductSupplyRequestIdList)) {
      toastify('선택된 발주가 존재하지 않습니다.', { variant: 'warn' });
      return;
    }
    if (selectedProductSupplyRequestList.some((request) => request.statusCode === 'CANCEL')) {
      toastify('이미 발주취소된 항목을 포함하고 있습니다.', { variant: 'warn' });
      return;
    }
    if (selectedProductSupplyRequestList.some((request) => request.statusCode === 'CONFIRM')) {
      toastify('발주확정된 항목을 포함하고 있습니다.', { variant: 'warn' });
      return;
    }

    if (status !== 'CANCEL') updateStatus(status);
    else if (
      status === 'CANCEL' &&
      window.confirm('취소된 발주는 되돌릴 수 없습니다. 취소하시겠습니까?')
    )
      updateStatus(status);
  };

  const statusButtons = [
    {
      label: '거래처 전송',
      disableCheckFunction: isSendButtonDisabled,
      onClick: () => handleStatusUpdate('PETFRIENDS_REGISTER'),
    },
    {
      label: '발주 취소',
      disableCheckFunction: isCancelButtonDisabled,
      onClick: () => handleStatusUpdate('CANCEL'),
    },
  ];

  const renderStatusButtons = () =>
    statusButtons.map(({ label, disableCheckFunction, onClick }) => (
      <Button
        key={label}
        $variant='secondary'
        disabled={disableCheckFunction(searchOptionsSnapshot.current.statusCode)}
        onClick={onClick}
      >
        {label}
      </Button>
    ));

  return (
    <Styled.HeaderButtons>
      <div>
        <span>발주 등록</span>
        <Button $variant='light' onClick={handleMultipleRegisterClick}>
          발주 대량 등록
        </Button>
        <Button $variant='light' onClick={handleSeparateRegisterClick}>
          발주 개별 등록
        </Button>
      </div>
      <div>
        <span>발주상태 일괄처리</span>
        {renderStatusButtons()}
      </div>
      <div>
        <span>기타</span>
        <Button $variant='light' onClick={handleExcelDownloadClick}>
          엑셀 다운로드
        </Button>
      </div>
    </Styled.HeaderButtons>
  );
};

HeaderButtons.propTypes = {
  searchOptionsSnapshot: PropTypes.shape({
    current: PropTypes.shape({
      centerId: PropTypes.string,
      productSupplyTypeCode: PropTypes.string,
      statusCode: PropTypes.string,

      productSupplyRequestDay: PropTypes.arrayOf(PropTypes.instanceOf(new Date())),
      totalReceiveConfirmDay: PropTypes.arrayOf(PropTypes.instanceOf(new Date())),
      receiveAvailableDay: PropTypes.arrayOf(PropTypes.instanceOf(new Date())),

      searchOption: PropTypes.string,
      query: PropTypes.string,
    }),
  }),
  selectedProductSupplyRequestList: PropTypes.arrayOf(PropTypes.object),
  fetchSupplyRequestList: PropTypes.func.isRequired,
  controlledPageIndex: PropTypes.number.isRequired,
};

export default HeaderButtons;
